*{
font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
color: rgb(7, 15, 15);
margin: 0;
padding: 0;
box-sizing: border-box;

}

body{

    background-color: #f6ede7;


}

.Auto{
  display: flex;
  justify-content: center;
  align-items: center;

}

.Lista{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;

}


.Container {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.3s;

  width: 280px;
  height: 340px;
  margin: 10px;

}

.Container:hover{
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.589);
  border: 2px solid rgba(255, 255, 255, 0.3);


}

.Bloco{
  
  width: 260px;
  height: 200px;
  margin: auto;
  padding-top: 5px;
}


.CardText{
  margin: auto;
  padding: 10px;

}

.Flex-end {
  display: flex;
  justify-content: flex-end;
}

.Flex-start {
  display: flex;
  justify-content: flex-start;
}

.Flex-bottom{
  display: flex;
  align-items: flex-end;
}

.Flex-top{
  display: flex;
  align-items: flex-start;

}

.BigButton{
    width: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 16px;

}

.BigButton:hover{
  border: 4px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.589);
  border-radius: 16px;
  border: 1px solid rgba(0,0,0,0);
  background-color: rgba(220, 121, 0, 0.897);
  color:white;

}

.PrecoTag{
  
  padding-left: 10px;
}

.grid-container {
  display: grid;
  gap: 10px;
 background-color: #f6ede7;
  padding: 10px;
}

.grid-item {
  background-color: rgba(213, 213, 213, 0.8); 
  text-align: center;
  padding: 20px;
  font-size: 30px;
}

.item1 {
  grid-column: 1;
  grid-row: 1 / span 2;
}

.item2 {
  grid-column: 2 / span 2;
  grid-row: 1;
}

.item5 {
  grid-column: 1 / span 3;
  grid-row: 3;
  text-align: left;
  font-size: large;
}

